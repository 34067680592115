(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var splide_1 = require("@splidejs/splide");
var banner = document.querySelector(".splide.banner");
var autoplay = banner.dataset.autoplay === "1";
var interval = parseInt(banner.dataset.interval);
var splide = new splide_1.Splide('.splide.banner', {
  type: 'loop',
  perPage: 1,
  autoplay: autoplay,
  interval: interval,
  pauseOnHover: false,
  resetProgress: false,
  arrows: false,
  pagination: false,
  classes: {
    arrows: 'splide__arrows',
    arrow: 'splide__arrow',
    prev: 'splide__arrow--prev',
    next: 'splide__arrow--next'
  }
}).mount();

},{"@splidejs/splide":"@splidejs/splide"}]},{},[1]);
